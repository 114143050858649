"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var init_searchBox_and_hamburgerMenu = function init_searchBox_and_hamburgerMenu() {
  var body = document.getElementById('body');
  var fadeContent = document.getElementById('content-fade');
  var btnSearch = document.getElementById('search-button');
  var btnMobile = document.getElementById('hamburger');
  var menuSearch = document.getElementById('search-box');
  var menuMobile = document.getElementById('mobile-menu');
  var isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  var elementHide = function elementHide(btn, menu) {
    if (!menu.classList.contains('hidden')) {
      menu.classList.add('hidden');
      var _iterator = _createForOfIteratorHelper(btn.children),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var child = _step.value;
          child.classList.toggle('hidden');
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      ;
    }
  };
  var elementToggle = function elementToggle(btn, menu) {
    menu.classList.toggle('hidden');
    var _iterator2 = _createForOfIteratorHelper(btn.children),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var child = _step2.value;
        child.classList.toggle('hidden');
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
    ;
    if (menu.getAttribute('id') === 'search-box') {
      elementHide(btnMobile, menuMobile);
    } else {
      elementHide(btnSearch, menuSearch);
    }
    if (menuSearch.classList.contains('hidden') && menuMobile.classList.contains('hidden')) {
      fadeContent.classList.add('hidden');
    } else {
      fadeContent.classList.remove('hidden');
    }
  };
  var isTargetConnectedToHeader = function isTargetConnectedToHeader(event) {
    var target = event.target;
    while (target !== null) {
      if (target.tagName === 'HEADER') {
        return true;
      }
      target = target.parentElement;
    }
    return false;
  };
  var clickOutside = function clickOutside(event) {
    var target = event.target.className;
    if (target.includes('o-header__container') || target.includes('content-fade') || !isTargetConnectedToHeader(event)) {
      fadeContent.classList.add('hidden');
      elementHide(btnMobile, menuMobile);
      elementHide(btnSearch, menuSearch);
    }
  };
  if (btnMobile && menuMobile) {
    isTouchDevice == true ? btnMobile.addEventListener("touchend", function () {
      return elementToggle(btnMobile, menuMobile);
    }) : btnMobile.addEventListener("mouseup", function () {
      return elementToggle(btnMobile, menuMobile);
    });
  }
  if (btnSearch && menuSearch) {
    isTouchDevice == true ? btnSearch.addEventListener("touchend", function () {
      return elementToggle(btnSearch, menuSearch);
    }) : btnSearch.addEventListener("mouseup", function () {
      return elementToggle(btnSearch, menuSearch);
    });
  }
  if (body) {
    isTouchDevice == true ? body.addEventListener("touchend", function () {
      return clickOutside(event);
    }) : body.addEventListener("mouseup", function () {
      return clickOutside(event);
    });
  }
};
init_searchBox_and_hamburgerMenu();